import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import qs from 'qs';
import Cookies from 'js-cookie';

import Header from 'components/header';
import Footer from 'components/footer';

import { GlobalStyle } from 'styles/normalize';

import 'styles/fonts.scss';
import 'styles/index.scss';
import 'styles/icons.scss';

import replaceLocalePhoneNumber from 'utils/functions/replaceLocalePhoneNumber';
import filterPrismicDataByLanguage from 'utils/functions/filterPrismicDataByLanguage';
import { hideLiveChat } from 'utils/functions/hideLiveChat';

import { setLanguage } from 'state/actions/language';

// 96824 - this file incl preview changes

const Layout = ({ 
  children, 
  location, 
  preview, 
  isHomePage, 
  previewHeaderData, 
  previewFooterData, 
  language,
  alternateLanguages,
  setLanguage,
  localePhoneNumber,
  universalProps,
  errorMessages,
  icons,
  footerProps,
  headerData,
  uid
}) => {
  setLanguage(language)
  
  useEffect(() => {
    localStorage.setItem("langCode", language)

    if (location) {
      if (location.search) {
        var gclidValue = qs.parse(location.search, { parameterLimit: 1 });
        
        if (gclidValue) {
          function getExpiryRecord() {
            var expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds
            
            var expiryDate = new Date().getTime() + expiryPeriod;
  
            return expiryDate
          }
  
          const expiryDate = getExpiryRecord();
          const itemToSave = {
            value: gclidValue['?gclid'],
            expiry: expiryDate
          }
  
          localStorage.setItem("gclid", JSON.stringify(itemToSave));

        }

      }
    }
    const now = new Date();
    const gclidFromLocalStorage = localStorage.getItem("gclid")
    const parsedItem = JSON.parse(gclidFromLocalStorage)

    if (parsedItem) {
      if (now.getTime() > parsedItem.expiry) {
        localStorage.removeItem("gclid")
      }
    }

    hideLiveChat();    
  }, [])

  const localePhoneNumbers = !preview && replaceLocalePhoneNumber(localePhoneNumber, language, uid);
  const errorMessagess = !preview && filterPrismicDataByLanguage(errorMessages, language);

  const previewHeaderLayoutData = preview && previewHeaderData ? previewHeaderData : null;
  const previewFooterLayoutData = preview && previewFooterData ? previewFooterData : null;
  
    return (
      <React.Fragment>
        <Header
          location={location}
          localePhoneNumber={localePhoneNumbers}
          errorMessages={preview ? '' : errorMessagess.node.data}
          previewHeaderData={previewHeaderLayoutData}
          preview={preview}
          language={language}
          alternateLanguages={alternateLanguages}
          headerData={preview ? previewHeaderLayoutData : headerData}
        />
        {children}
        <Footer 
          localePhoneNumber={localePhoneNumbers}
          location={location}
          isHomePage={isHomePage}
          previewFooterData={previewFooterLayoutData}
          preview={preview}
          language={language}
          footerProps={footerProps}
          alternateLanguages={alternateLanguages}
        /> 
        <GlobalStyle />
      </React.Fragment>
    )
}

const mapDispatchToProps = dispatch => ({
  setLanguage: lang => dispatch(setLanguage(lang))
});

export default connect(null, mapDispatchToProps)(Layout)

Layout.propTypes = {
  alternateLanguages: PropTypes.array,
  children: PropTypes.node.isRequired,
  errorMessages: PropTypes.array,
  footerProps: PropTypes.object,
  headerData: PropTypes.object,
  icons: PropTypes.array,
  isHomePage: PropTypes.bool,
  language: PropTypes.string,
  localePhoneNumber: PropTypes.array,
  location: PropTypes.object,
  preview: PropTypes.bool,
  previewFooterData: PropTypes.object,
  previewHeaderData: PropTypes.object,
  setLanguage: PropTypes.func,
  uid: PropTypes.string,
  universalProps: PropTypes.array,
}
